import React from 'react';
import { useHistory } from 'react-router-dom';

import HeaderEditable from '@/Components/HeaderEditable';

import {
  Container,
  ArrowLeftIcon,
  Content,
  AppsContainer,
  App,
  Title,
} from './styles';

function Aplicativos() {
  const history = useHistory();

  function goBack() {
    history.goBack();
  }

  return (
    <Container>
      <HeaderEditable>
        <ArrowLeftIcon onClick={goBack} />
      </HeaderEditable>

      <Content>
        <Title>Aplicativos</Title>

        <AppsContainer>
           <App>
            <span>Mecanizado GPS</span>
            <a
              href="https://docs.google.com/uc?export=download&id=1JulM1s4V9My0iSelu7UHhFKwsHOCvWxe"
              target="blank"
              download
            >
              Baixar
            </a>
          </App>
          <App>
            <span>Acasalamento</span>
            <a
              href="https://docs.google.com/uc?export=download&id=1VgIA_d_U9UMAUY_4PyXcUuJYLRX0T9Xb"
              target="blank"
              download
            >
              Baixar
            </a>
          </App>
          <App>
            <span>Coleta</span>
            <a
              href="https://docs.google.com/uc?export=download&id=12WYBkCj3kNzAboDIBEtjhJLW-Ynfa3oW"
              download
              target="blank"
            >
              Baixar
            </a>
          </App>
          <App>
            <span>Colheita</span>
            <a
              href="https://docs.google.com/uc?export=download&id=19e2PQzKw57wsyEk-xNTl7BCHitZMoPAL"
              download
              target="blank"
            >
              Baixar
            </a>
          </App>
          <App>
            <span>Estria</span>
            <a
              href="https://docs.google.com/uc?export=download&id=1rgWlScFfpiY02oBkbhAqNX5w7c8Gyp39"
              download
              target="blank"
            >
              Baixar
            </a>
          </App>
          <App>
            <span>Leiteria</span>
            <a
              href="https://docs.google.com/uc?export=download&id=1-uWauA1XuQ3cJBIn03rPrytm0QVh5m5y"
              download
              target="blank"
            >
              Baixar
            </a>
          </App>
          <App>
            <span>Novo MIPD</span>
            <a
              href="https://docs.google.com/uc?export=download&id=15b2Vu-zqVZ0yjw8NfWR8P25j2L5bulti"
              download
              target="blank"
            >
              Baixar
            </a>
          </App>
          <App>
            <span>Tratos</span>
            <a
              href="https://docs.google.com/uc?export=download&id=1Y1YmhWS0_lh_wnWaKYU70lAAWLFuOGr9"
              download
              target="blank"
            >
              Baixar
            </a>
          </App>
        </AppsContainer>
      </Content>
    </Container>
  );
}

export default Aplicativos;
